import Legal from "../pages/Legal/Legal";
import MainPage from "../pages/MainPage/MainPage";
import Privacy from "../pages/Privacy/Privacy";
import AccountDeletion from "../pages/AccountDeletion/AccountDeletion";

const routes = {
  HOME: "/",
  PRIVACY: "/privacy",
  TERMS_OF_USE: "/terms_of_use",
  ACCOUNT_DELETION: "/account_deletion",
};

const config = [
  {
    path: routes.HOME,
    exact: true,
    component: MainPage,
  },
  {
    path: routes.PRIVACY,
    exact: true,
    component: Privacy,
  },
  {
    path: routes.TERMS_OF_USE,
    exact: true,
    component: Legal,
  },
  {
    path: routes.ACCOUNT_DELETION,
    exact: true,
    component: AccountDeletion,
  },
];

export default routes;
export { config };
