import "./Privacy.scss";
export default function Privacy() {
  return (
    <div className="privacy">
      <h1>RGPD :</h1>
      <h2>PREAMBULE</h2>
      <p>
        La présente politique de confidentialité vous informe sur la manière
        dont Vibes utilise et protège les informations que vous nous
        transmettez, le cas échéant, lorsque vous utilisez le présent site
        accessible à partir de l’URL suivante : vibesapp.eu (ci-après le « Site
        »). Veuillez noter que cette politique de confidentialité est
        susceptible d’être modifiée ou complétée à tout moment par Vibes,
        notamment en vue de se conformer à toute évolution légale ou
        technologique. Dans un tel cas, la date de sa mise à jour sera
        clairement identifiée en tête de la présente politique. Ces
        modifications vous engagent dès leur mise en ligne. Il convient par
        conséquent que vous consultiez régulièrement la présente politique de
        confidentialité afin de prendre connaissance de ces éventuelles
        modifications.
      </p>
      <h2>1. PARTIES</h2>
      <p>
        La présente politique de confidentialité est applicable entre l’éditeur
        du Site, ci-après « SAS VIBES », et toute personne se connectant au
        Site, ci-après « l’Internaute ».
      </p>
      <h2>2. DEFINITIONS</h2>
      <p>
        « Contenus du Site » : éléments de toute nature publiée sur le Site,
        protégés ou non par un droit de propriété intellectuelle, tel que
        textes, images, designs, présentations, vidéos, schémas, structures,
        bases de données ou logiciels. « Internaute » : toute personne se
        connectant au Site. « Site » : site internet accessible à l’URL
        vibesapp.eu, ainsi que les sous-sites, sites miroirs, portails et
        variations d’URL y afférant.
      </p>
      <h2>3. CHAMP D’APPLICATION</h2>
      <p>
        La présente politique de confidentialité est applicable à tout
        Internaute. La simple connexion et/ou navigation sur le Site emportera
        votre acceptation pleine et entière de la présente politique de
        confidentialité. Par ailleurs, le fait de cliquer sur « J’accepte » dans
        le bandeau d’information relatif aux cookies affiché sur le Site emporte
        votre confirmation de cette acceptation, tout en vous permettant de
        personnaliser les cookies qui vous seront ou non appliqués. Vous
        reconnaissez du même fait en avoir pris pleinement connaissance et les
        accepter sans restriction. L’Internaute reconnaît la valeur de preuve
        des systèmes d’enregistrement automatique de Vibes et, sauf pour lui
        d’apporter preuve contraire, il renonce à les contester en cas de
        litige. L’acceptation de la présente politique de confidentialité
        suppose de la part des Internautes qu’ils jouissent de la capacité
        juridique nécessaire pour cela ou qu’ils aient au moins 16 ans, ou à
        défaut qu’ils en aient l’autorisation d’un tuteur ou d’un curateur s’ils
        sont incapables, de leur représentant légal s’ils ont moins de 16 ans,
        ou encore qu’ils soient titulaires d’un mandat s’ils agissent pour le
        compte d’une personne morale.
      </p>
      <h2>4. DONNEES PERSONNELLES</h2>
      <p>
        Conformément au Règlement général sur la protection des données (RGPD)
        adopté par le Parlement européen le 14 avril 2016 et à la législation
        nationale en vigueur, Vibes vous fournit les informations suivantes :
      </p>
      <h3>1. Identité du responsable du traitement</h3>
      <p>
        Le responsable de la collecte et des données traitées sur le Site est la
        SAS VIBES, Association Loi 1901, dont le siège social se situe au 12 rue
        de seine, 75006 Paris, sas.vibes@outlook.fr
      </p>
      <h3>2. Collecte de données par Vibes</h3>
        <p>
            L'application Vibes collecte des données afin de fournir ses services, améliorer votre expérience utilisateur, et respecter ses obligations légales et contractuelles. La collecte de données se fait de manière transparente et dans le respect des lois en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD).
            Nous collectons des données directement auprès des utilisateurs lors de leur inscription, de leur utilisation de l'application ou via des outils analytiques tiers intégrés dans l'application.
        </p>
      <h3>3. Données collectées</h3>
        <p>Dans le cadre de l'utilisation de Vibes, les types de données suivants peuvent être collectés :</p>
        <p>1. Données d'identification personnelle :</p>
        <span>• Nom, prénom</span>
        <span>• Adresse email</span>

        <p>2. Données techniques et de navigation :</p>
        <span>• Adresse IP</span>
        <span>• Type de navigateur et système d'exploitation</span>
        <span>• Identifiant de l'appareil</span>

        <p>3. Données de localisation (si activé) :</p>
        <span>• Position géographique en temps réel pour personnaliser les services</span>

        <p>4. Autres informations fournies volontairement par l'utilisateur :</p>
        <span>• Contenu des messages envoyés via l'application</span>
        <span>• Avis, commentaires ou retours d’expérience</span>
        <h3>4. Données collectées lors de la navigation</h3>
      <p>
        Lors de la navigation sur le Site, vous consentez à ce que Vibes
        collecte les informations relatives : à votre utilisation du Site ; à
        vos données de connexion (horaires, adresse IP…).
      </p>
      <p>
        Les données collectées lors de la navigation font l’objet d’un
        traitement automatisé ayant pour finalité de :{" "}
      </p>
      <p>1. Assurer et améliorer la sécurité de notre Site et application ;</p>
      <p>
        2. Développer, exploiter, améliorer, fournir et gérer le Site et
        application ;{" "}
      </p>
      <p>
        3. Adresser des informations et contacter les personnes, y compris par
        e-mail ;
      </p>
      <p>4. Éviter toute activité illicite ou illégale ;</p>
      <p>
        5. Faire respecter les conditions relatives à l’utilisation du Site.
      </p>
      <p>
        6. Bases juridiques du traitement Les données collectées lors de la
        navigation ont pour base juridique l’intérêt légitime de Vibes, à savoir
        réaliser une analyse des comportements sur le Site et obtenir une
        sécurité et un fonctionnement améliorés du Site. Certaines de ces
        données, comme celles issues de l’implantation de certains cookies,
        peuvent avoir pour base juridique le consentement des personnes. Les
        données collectées lors de l’utilisation du formulaire de contact ont
        pour base juridique le consentement des personnes concernées. Les
        données collectées lors de l’utilisation du formulaire de newsletter ont
        pour base juridique le consentement des personnes concernées.
      </p>
      <h2>5. Respects des droits</h2>
      <p>
        Vous disposez des droits suivants concernant vos données personnelles,
        que vous pouvez exercer en nous écrivant à notre adresse postale ou en
        adressant un email à l’adresse suivante : sas.vibes@gmail.com
      </p>
      <p>
        1. Droit d’information, d’accès et de communication des données Vous
        avez la possibilité d’accéder aux données personnelles qui vous
        concernent. En raison de l’obligation de sécurité et de confidentialité
        dans le traitement des données à caractère personnel qui incombe à
        Vibes, votre demande sera uniquement traitée si vous rapportez la preuve
        de votre identité, notamment par la production d’un scan de votre titre
        d’identité valide (en cas de demande par email) ou d’une photocopie
        signée de votre titre d’identité valide (en cas de demande adressée par
        écrit), tous deux accompagnés de la mention « j’atteste sur l’honneur
        que la copie de cette pièce d’identité est conforme à l’original. Fait à
        … le … », suivie de votre signature. Pour vous aider dans votre
        démarche, vous trouverez ici un modèle de courrier élaboré par la Cnil.
      </p>
      <p>
        2. Droit de rectification, de suppression et droit à l’oubli des données
        Vous avez la possibilité de demander la rectification, la mise à jour,
        le verrouillage ou encore l’effacement de vos données personnelles qui
        peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou
        obsolètes. Vous pouvez également définir des directives générales et
        particulières relatives au sort des données à caractère personnel après
        votre décès. Le cas échéant, les héritiers d’une personne décédée
        peuvent exiger de prendre en considération le décès de leur proche et/ou
        de procéder aux mises à jour nécessaires. Pour vous aider dans votre
        démarche, vous trouverez ici un modèle de courrier élaboré par la Cnil.
      </p>
      <p>
        3. Droit d’opposition au traitement de données Vous avez la possibilité
        de vous opposer à un traitement de vos données personnelles. Pour se
        faire, il vous conviendra d’adresser un email à l’adresse suivante :
        sas.vibes@outlook.com. Dans ce mail, il vous faudra préciser les données
        que vous souhaitez voir supprimer ainsi que les raisons justifiant cette
        demande, sauf cas de prospection commerciale.
      </p>
      <p>
        4. Droit à la portabilité des données Vous avez le droit de recevoir les
        données personnelles que vous nous avez fournies dans un format
        transférable, ouvert et lisible.
      </p>
      <p>
        5. Droit à la limitation du traitement Vous avez le droit de demander
        que le traitement de vos données personnelles par Vibes soit limité.
        Ainsi, vos données ne pourront qu’être conservées et non plus utilisées
        par Vibes.
      </p>
      <p>
        6. Retrait du consentement Votre consentement est indispensable au
        traitement de vos données par Vibes. Toutefois, il vous est possible de
        retirer celui-ci à tout moment. Ce retrait conduira à la suppression des
        données personnelles vous concernant. Les services nécessitant le
        traitement de vos données par Vibes ne seront néanmoins plus
        accessibles.
      </p>
      <p>
        7. Délais de réponse Vibes s’engage à répondre à votre demande d’accès,
        de rectification ou d’opposition ou toute autre demande complémentaire
        d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois
        à compter de la réception de votre demande.
      </p>
      <p>
        8. Plainte auprès de l’autorité compétente Si vous considérez que Vibes
        ne respecte pas ses obligations au regard de vos informations
        personnelles, vous pouvez adresser une plainte ou une demande auprès de
        l’autorité compétente. En France, l’autorité compétente est la Cnil à
        laquelle vous pouvez adresser une demande ici.
      </p>
      <h2>6. STIPULATIONS FINALES</h2>
      <p>
        1. Modifications La présente Politique de Confidentialité peut être
        modifiée à tout moment par Vibes. Les conditions applicables à
        l’Internaute sont celles en vigueur lors de sa connexion sur le Site.
        Toute modification substantielle de la présente politique de
        confidentialité fera l’objet d’une information lors de la première
        connexion de l’Internaute suite à leur entrée en vigueur. Cette nouvelle
        politique de confidentialité devra alors faire l’objet d’une nouvelle
        acceptation.
      </p>
      <p>
        2. Entièreté La nullité d’une des clauses du présent contrat
        n’entraînera pas la nullité des autres clauses du contrat ou du contrat
        dans sa globalité, qui garderont leur plein effet et portée. Dans une
        telle hypothèse, les parties devront dans la mesure du possible
        remplacer la stipulation annulée par une stipulation valable
        correspondant à l’esprit et à l’objet des présentes.{" "}
      </p>
      <p>
        3. Non-renonciation L’absence d’exercice par Vibes des droits qui lui
        sont reconnus par les présentes ne pourra en aucun cas être interprétée
        comme une renonciation à faire valoir lesdits droits.{" "}
      </p>
      <p>
        4. Langues Les présentes conditions sont proposées en français. <p></p>
        5. Clauses abusives Les stipulations des présentes conditions
        s’appliquent sous réserve du respect des dispositions impératives du
        Code de la consommation concernant les clauses abusives dans les
        contrats conclus entre un professionnel et un consommateur.
      </p>
      <h2>7. LITIGES</h2>
      <p>
        1.Droit applicable La présente Politique de Confidentialité est soumise
        à l’application du droit français et de la règlementation européenne,
        notamment le Règlement européen sur la protection des données.
      </p>
      <p>
        2. Litiges En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous
        litiges qui pourraient survenir dans le cadre de l’exécution des
        présentes conditions générales et dont la solution n’aura pu être
        trouvée préalablement à l’amiable entre les parties devra être soumis à
        Medicys.
      </p>
      <p>
        3. Arbitrage Tout litige relatif au présent contrat ou en relation avec
        celui-ci sera tranché par voie d’arbitrage conformément au règlement
        FastArbitre de l’Institut digital d’arbitrage et de médiation.
      </p>
    </div>
  );
}
