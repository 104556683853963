import "./AccountDeletion.scss";
export default function AccountDeletion() {
    return (
        <div className="accountDeletion">
            <h1>Suppression de votre compte Vibes</h1>
            <p>Nous comprenons que vous souhaitiez supprimer votre compte. Voici les étapes simples à suivre directement depuis l’application :</p>
            <p>1. Ouvrez l’application Vibes et connectez-vous à votre compte utilisateur.</p>
            <p>2. Accédez à votre compte utilisateur. Accédez au menu des réglages en cliquant sur le petit rouage situé en haut à droite de votre écran.</p>
            <p>3. Sélectionnez l’option “Déconnexion et suppression des données personnelles”.</p>
            <p>4. Confirmez votre choix en suivant les instructions affichées.</p>
        </div>
    );
}
